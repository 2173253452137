<template>
  <c-box
    height="100%"
    width="100%"
    display="flex"
    flex-direction="column"
    :cursor="_hasOnClick ? 'pointer' : 'default'"
    background-color="white"
    :border-radius="['12px', '20px']"
    border="1px solid #f2f2f2"
    box-shadow="0px 5px 30px 0px #0000000D"
    @click="onClick"
  >
    <c-flex
      width="100%"
      :padding="['16px', '30px 20px 10px 20px']"
      gap="16px"
      align-items="center"
    >
      <c-box
        :min-width="['50px', '70px']"
        :width="['50px', '70px']"
        :height="['50px', '70px']"
        overflow="hidden"
        border-radius="100%"
        background-color="lightGray.900"
      >
        <c-image
          object-fit="cover"
          :src="getPhotoUser(photo)"
          :alt="name"
        />
      </c-box>
      <c-box
        :font-size="['16px', '18px']"
        font-weight="500"
        text-decoration="inherit"
        flex-grow="1"
        class="line-clamp-2"
      >
        {{ name }}
      </c-box>
      <c-box
        as="span"
        border-width="1px"
        border-style="solid"
        border-radius="1000px"
        :border-color="styles.borderColor"
        :background-color="styles.backgroundColor"
        :padding="['7px 16px', '9px 22px']"
        display="inline-block"
        vertical-align="middle"
      >
        <BaseText
          size-mobile="14px-2"
          size-desktop="16px"
          white-space="nowrap"
          flex="1"
          :color="styles.color"
        >
          {{ _labelStatus }}
        </BaseText>
      </c-box>
    </c-flex>
    <c-box>
      <BaseDivider margin="0" />
    </c-box>
    <c-box
      padding="16px 20px 20px 20px"
      display="flex"
      flex-direction="column"
      justify-content="flex-end"
      height="100%"
      gap="16px"
    >
      <c-box>
        <BaseText
          size-mobile="14px-2"
          size-desktop="16px"
          white-space="nowrap"
          flex="1"
          color="primary.400"
        >
          {{ programName }}
        </BaseText>
      </c-box>
      <c-box
        width="100%"
        display="flex"
        :flex-direction="['column', 'row']"
        :align-items="['start', 'end']"
        justify-content="space-between"
        height="100%"
      >
        <c-box
          display="flex"
          gap="8px"
          align-items="center"
        >
          <c-box
            height="15px"
            width="15px"
            min-height="15px"
            min-width="15px"
          >
            <inline-svg
              :src="require('@/assets/ic-mail.svg')"
              height="100%"
              width="100%"
              fill="#555555"
            />
          </c-box>
          <BaseText
            size-mobile="12px"
            size-desktop="14px-2"
            color="#555555"
          >
            {{ phone }}
          </BaseText>
        </c-box>
        <c-box
          display="flex"
          gap="8px"
          align-items="center"
        >
          <c-box
            height="15px"
            width="15px"
            min-height="15px"
            min-width="15px"
          >
            <inline-svg
              :src="require('@/assets/ic-phone.svg')"
              height="100%"
              width="100%"
              fill="#555555"
            />
          </c-box>
          <BaseText
            size-mobile="12px"
            size-desktop="14px-2"
            color="#555555"
          >
            {{ email }}
          </BaseText>
        </c-box>
      </c-box>
    </c-box>
  </c-box>
</template>

<script>
import generalMixin from '@/utils/general-mixins'
import BaseDivider from '@/components/elements/base-divider.vue'
import BaseText from '@/components/elements/base-text.vue'

export default {
  name: 'CardClient',
  components: { BaseText, BaseDivider },
  mixins: [
    generalMixin,
  ],
  props: {
    name: {
      type: String,
      required: true,
    },
    programName: {
      type: String,
      required: true,
    },
    phone: {
      type: String,
      required: true,
    },
    email: {
      type: String,
      required: true,
    },
    photo: {
      type: String,
      default: '',
    },
    status: {
      type: String,
      default: 'active',
      validator(value) {
        return ['active', 'pending', 'nonactive'].includes(value)
      },
    },
  },
  computed: {
    _hasOnClick() {
      return !!this?.$listeners?.['on-click']
    },
    _labelStatus() {
      switch (this.status) {
        case 'active':
          return 'Aktif'
        case 'pending':
          return 'Pending'
        case 'nonactive':
        default:
          return 'Nonaktif'
      }
    },
    styles() {
      switch (this.status) {
        case 'active':
          return {
            color: '#008C81',
            backgroundColor: '#008C8133',
            borderColor: '#008C81',
          }
        case 'pending':
          return {
            color: '#DA6D06',
            backgroundColor: '#FDEDCB',
            borderColor: '#DA6D06',
          }
        case 'nonactive':
        default:
          return {
            color: '#888',
            backgroundColor: '#F2F2F2',
            borderColor: '#888',
          }
      }
    },
  },
  methods: {
    onClick() {
      this.$emit('on-click')
    },
  },
}
</script>
