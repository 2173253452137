<template>
  <c-box
    width="100%"
    max-width="1270px"
    background-color="#FFF"
    margin-bottom="16px"
    :box-shadow="['none', '2px 2px 10px rgba(0, 0, 0, 0.15)']"
    :border-radius="['0px', '16px']"
    :padding="['16px', '30px']"
    :min-height="['unset', '74vh']"
  >
    <Portal to="breadcrumb">
      <BreadcrumbPath
        padding="20px 90px"
        :paths="[
          { label: 'Beranda', href: { name: 'nutrisionist.managementClient' } },
          { label: 'Manajemen Klien', isCurrent: true },
        ]"
      />
    </Portal>
    <c-box
      w="100%"
      max-width="1200px"
      mx="auto"
    >
      <c-box>
        <BaseText
          size-mobile="20px"
          size-desktop="28px"
          text-align="center"
        >
          Manajemen Klien
        </BaseText>
      </c-box>
      <c-box
        margin-top="30px"
        display="flex"
        gap="16px"
        align-items="center"
      >
        <c-input-group
          w="100%"
          size="lg"
        >
          <c-input-left-element>
            <c-image
              :src="require('@/assets/ic-search.svg')"
              alt="icon"
            />
          </c-input-left-element>
          <c-input
            v-model="search"
            type="text"
            placeholder="Pencarian"
            border-radius="20px"
          />
        </c-input-group>
        <c-box> Show: </c-box>
        <c-box>
          <c-flex justify-content="space-between">
            <c-menu :close-on-select="true">
              <c-menu-button
                border-radius="40px"
                background-color="superLightGray.900"
                pl="20px"
                pr="30px"
                py="10px"
                h="40px"
                color="gray.900"
                font-size="14px"
                line-height="21px"
                font-family="Roboto"
                font-weight="normal"
                :display="['none', 'flex']"
              >
                <c-image
                  :src="require('@/assets/ic-filter.svg')"
                  alt="icon"
                  display="inline-block"
                  margin-right="5px"
                />
                Filter
              </c-menu-button>
              <c-menu-list
                z-index="10"
                min-width="240px"
              >
                <c-menu-option-group
                  default-value="Semua"
                  type="radio"
                  @change="status = $event"
                >
                  <c-menu-item-option
                    v-for="item in statuses"
                    :key="item.value"
                    :value="item.value"
                    border-bottom="1px solid #F2F2F2"
                    font-size="18px"
                    line-height="27px"
                    py="16px"
                    px="20px"
                  >
                    {{ item.label }}
                  </c-menu-item-option>
                </c-menu-option-group>
              </c-menu-list>
            </c-menu>
            <c-button
              v-if="false"
              border-radius="20px"
              background-color="superLightGray.900"
              px="15px"
              py="4px"
              color="gray.900"
              font-size="16px"
              line-height="24px"
              font-family="Roboto"
              font-weight="700"
              :display="['none', 'inline-block']"
            >
              <c-image
                :src="require('@/assets/ic-filter.svg')"
                alt="icon"
                display="inline-block"
                margin-right="5px"
              />
              Filter
            </c-button>
          </c-flex>
          <c-button
            border-radius="0"
            background-color="transparent"
            px="15px"
            py="4px"
            color="gray.900"
            font-size="16px"
            line-height="24px"
            font-family="Roboto"
            font-weight="700"
            :display="['inline-block', 'none']"
          >
            <c-image
              :src="require('@/assets/ic-filter.svg')"
              alt="icon"
              display="inline-block"
              margin-right="5px"
            />
          </c-button>
        </c-box>
      </c-box>

      <c-grid
        w="100%"
        :px="['20px', '0']"
        :template-columns="['repeat(1, 1fr)', 'repeat(2, minmax(0, 1fr))']"
        :grid-gap="[0, '10px']"
        margin-top="30px"
      >
        <CardClient
          v-for="client in clients"
          :key="client.id"
          :status="cardClientStatus(client.programs.status)"
          :name="`${client.firstName} ${client.lastName}`"
          :photo="client.photoUrl"
          :program-name="client.programs.name"
          :phone="client.phone"
          :email="client.email"
          @on-click="
            () => {
              $router.push({
                name: 'nutri.detail-client',
                params: { clientId: client.id },
              })
            }
          "
        />
      </c-grid>
      <c-flex
        justify-content="center"
        w="100%"
        margin-top="40px"
      >
        <c-flex justify-content="center">
          <btn-pagination
            bg="transparent"
            :disabled="currentPage === 1"
          >
            <c-icon
              name="chevron-left"
              size="24px"
            />
          </btn-pagination>
          <btn-pagination
            v-for="page in pages"
            :key="page"
            :background-color="page === currentPage ? ['rgba(0, 140, 129, 0.3)'] : ['transparent']"
            as="router-link"
            :to="{ name: $route.name, query: { ...$route.query, page } }"
          >
            {{ page }}
          </btn-pagination>
          <btn-pagination>
            <c-icon
              name="chevron-right"
              size="24px"
            />
          </btn-pagination>
        </c-flex>
      </c-flex>
    </c-box>
  </c-box>
</template>

<script>
import equal from 'fast-deep-equal'
import CardClient from '@/components/widgets/card-client.vue'
import BtnPagination from './btn-pagination.vue'
import BreadcrumbPath from '@/components/elements/breadcrumb-path.vue'
import BaseText from '@/components/elements/base-text.vue'

export default {
  name: 'ManagementClientPage',
  components: {
    BaseText,
    BreadcrumbPath,
    BtnPagination,
    CardClient,
  },
  data() {
    return {
      totalData: 0,
      perPage: 10,
      clients: [],
      search: this.$route.query.query ?? '',
      statuses: [
        { label: 'Semua', value: undefined },
        { label: 'Aktif', value: 'active' },
        { label: 'Tidak Aktif', value: 'non_active' },
        { label: 'Pending', value: 'pending' },
      ],
    }
  },
  computed: {
    currentPage() {
      return parseInt(this.$route.query.page ?? 1, 10)
    },
    pages() {
      let total = this.totalData ?? 1
      let perPage = this.perPage ?? 10
      let length = Math.ceil(total / perPage)
      return Array.from({ length }, (_, idx) => idx + 1)
    },
    status: {
      get() {
        return this.$route.query.status ?? undefined
      },
      set(v) {
        this.$router.replace({
          query: { ...this.$route.query, status: v },
        })
      },
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        this.listClients(route.query)
      },
    },
    search: {
      immediate: true,
      handler(query, prev) {
        if (equal(query, prev)) return

        this.$router.replace({
          query: {
            page: 1,
            query: query === '' ? undefined : query,
          },
        })
      },
    },
  },
  methods: {
    cardClientStatus(status) {
      switch (status) {
        case 'active':
        case 'pending':
          return status
        case 'done':
        case 'non_active':
        default:
          return 'nonactive'
      }
    },
    async listClients({ page = 1, query = null } = {}) {
      let axios = this.$store.getters.axios
      let params = new URLSearchParams()
      params.set('perpage', this.perPage)
      params.set('page', `${page}`)
      if (this.status != null) params.set('status', this.status)
      if (query != null) params.set('q', query)

      axios
        .get(`/v1/nutritionist/clients?${params.toString()}`)
        .then((it) => it.data)
        .then((it) => {
          this.clients = it.data
          this.totalData = it.meta.total
        })
    },
  },
}
</script>
